<template>
  <v-container>
    <state-indicate ref="indicate" v-slot:default="{isLoading,usePromise}">
      <v-row>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
        ></v-progress-linear>

        <template v-if="favorites.length">
          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            class="py-5"
            v-for="i in 12"
            v-if="isLoading"
            :key="i"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="card"
            ></v-skeleton-loader>
          </v-col>

          <v-col
            cols="12"
            lg="3"
            md="4"
            sm="6"
            v-for="product in favorites"
            :key="product.id"
            class="py-5"
          >
            <product-card :product="product"/>
          </v-col>
        </template>
        <v-card-text class="text-center" v-else>
          Your wishlist is empty.
        </v-card-text>
      </v-row>
    </state-indicate>
  </v-container>
</template>

<script>
    import Product from '@/models/Product';
    import ProductCard from "../../components/ProductCard";
    import StateIndicate from "../../components/utils/StateIndicate";
    import {mapState} from "vuex";

    export default {
        name: "MyWishlist",
        computed: {
            ...mapState({
                favorites: state => {
                    return state.favorites.items;
                }
            })
        },
        data() {
            return {}
        },
        mounted() {
            this.$refs.indicate.usePromise(this.fetchFavorites())
        },
        methods: {
            fetchFavorites() {
                return this.$store.dispatch('favorites/fetchFavorites', null)
            }
        },
        components: {
            StateIndicate, Product, ProductCard
        }
    }
</script>

<style scoped>

</style>
